import React from "react";

function Rooms() {
  return (
    <>
      <div className="kf-home-banner inner-banner">
        <div className="container">
          <h3>Hotel Rooms</h3>
          <span>
            <img src="./yachis/underline.png" alt="" />
          </span>
          <ul class="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/rooms">Rooms</a>
            </li>
          </ul>
        </div>
      </div>
      <section>
        <div className="container">
          {/* <!--HEADING 1 START--> */}
          <div className="col-md-12">
            <div className="kf-heading-1">
              <h3>Accommodations</h3>
              <span>
                <img src="./yachis/underline.png" alt="" />
              </span>
            </div>
          </div>
          {/* <!--HEADING 1 END--> */}
          <div className="row">
            <div className="col-md-4 col-sm-6">
              {/* <!--ACCOMODATION START--> */}
              <div className="kf-accomodation-dec">
                <figure>
                  <img src="./yachis/acconodation-1.jpg" alt="" />
                  <figcaption>
                    <a href="https://theyachis.com/rooms/">
                      <i className="fa fa-link"></i>
                    </a>
                  </figcaption>
                </figure>
                <div className="text">
                  <h5>
                    <a href="https://theyachis.com/rooms/">Room with Balcony</a>
                  </h5>
                  <p>
                    Hotel The Yachi's providing Luxury rooms with Balcony and
                    private bathroom. Those are the best rooms of the Hotel. You
                    must check in!
                  </p>
                  <ul className="servises-meta">
                    <li>
                      Price: 3500 ₹ &nbsp;&nbsp;{" "}
                      <a
                        href="https://theyachis.com/booknow/"
                        className="small-btn shadow-shadow"
                      >
                        Book Now
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!--ACCOMODATION END--> */}
            </div>
            <div className="col-md-4  col-sm-6">
              {/* <!--ACCOMODATION START--> */}
              <div className="kf-accomodation-dec">
                <figure>
                  <img src="./yachis/acconodation-2.jpg" alt="" />
                  <figcaption>
                    <a href="https://theyachis.com/rooms/">
                      <i className="fa fa-link"></i>
                    </a>
                  </figcaption>
                </figure>
                <div className="text">
                  <h5>
                    <a href="https://theyachis.com/rooms/">Deluxe Room</a>
                  </h5>
                  <p>
                    Hotel The Yachi's providing Deluxe rooms with private
                    bathroom. Those are the best rooms of the Hotel for family
                    purpose. You must check in Hotel!
                  </p>
                  <ul className="servises-meta">
                    <li>
                      Price: 1800 ₹ &nbsp;&nbsp;{" "}
                      <a
                        href="https://theyachis.com/booknow/"
                        className="small-btn shadow-shadow"
                      >
                        Book Now
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!--ACCOMODATION END--> */}
            </div>
            <div className="col-md-4  hidden-sm">
              {/* <!--ACCOMODATION START--> */}
              <div className="kf-accomodation-dec">
                <figure>
                  <img src="./yachis/acconodation-3.jpg" alt="" />
                  <figcaption>
                    <a href="https://theyachis.com/rooms/">
                      <i className="fa fa-link"></i>
                    </a>
                  </figcaption>
                </figure>
                <div className="text">
                  <h5>
                    <a href="https://theyachis.com/rooms/">Super Deluxe Room</a>
                  </h5>
                  <p>
                    Hotel The Yachi's providing Super Deluxe rooms with private
                    bathroom. Those are the best rooms of the Hotel for the
                    newly married couples. Must visit once!
                  </p>
                  <ul className="servises-meta">
                    <li>
                      Price: 2500 ₹ &nbsp;&nbsp;{" "}
                      <a
                        href="https://theyachis.com/booknow/"
                        className="small-btn shadow-shadow"
                      >
                        Book Now
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!--ACCOMODATION END--> */}
            </div>
          </div>
        </div>
      </section>
      <div className="kf_content_wrap">
        {/* <!--SPECIAL OFFER SECTION START--> */}
        <section className="kf-special-offer-wrap">
          <div className="container">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3>Our Special Offers</h3>
                <span>
                  <img src="./yachis/underline.png" alt="" />
                </span>
                <p>
                  Book The Yachi's Hotel in Rishikesh - Checkout hotel rates,
                  images, locations, web exclusive offers & deals, services &
                  amenities and book your stay online in Rishikesh directly from
                  Official Website of The Yachi's Hotel to enjoy complimentary
                  Breakfast, WiFI, Pay@Hotel. Best Deals Guaranteed!
                </p>
              </div>
            </div>
            <div className="bx-wrapper">
              <div className="bx-viewport">
                <ul className="bxslider">
                  <li className="bx-clone">
                    <div className="kf_content-des">
                      {/* <!--HEADING 1 END--> */}
                      <div className="col-md-4 col-sm-6">
                        {/* <!--SPECIAL OFFER THUMB START--> */}
                        <div className="thumb">
                          <figure>
                            <img src="./yachis/offers1.jpg" alt="" />
                            <figcaption>
                              <h3>Free Kids</h3>
                              <span>Special Offer</span>
                              <p>
                                The Yachi's is the best hotel in Budget at
                                Rishikesh with modern Facilities & guest
                                facilities in Rishikesh book your room now!
                              </p>
                              <div className="kf-offer-price">
                                <small>From</small>
                                <strong>1000 ₹</strong>
                                <a
                                  href="https://theyachis.com/booknow/"
                                  className="medium-btn"
                                >
                                  Book Now
                                </a>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                        {/* <!--SPECIAL OFFER THUMB END--> */}
                      </div>
                      <div className="col-md-8 col-sm-6">
                        <div className="col-md-6">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img src="./yachis/offers2.jpg" alt="" />
                              <span className="kf-thumb-heading">
                                Balcony Rooms
                              </span>
                              <figcaption>
                                <h3>Free WiFi</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>3500 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>

                        <div className="col-md-6">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img src="./yachis/offers3.jpg" alt="" />
                              <span className="kf-thumb-heading">
                                Deluxe Room
                              </span>
                              <figcaption>
                                <h3>Mountain View</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>1800 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>

                        <div className="col-md-6 hidden-sm hidden-xs">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img src="./yachis/offers4.jpg" alt="" />
                              <span className="kf-thumb-heading">
                                Super Deluxe Room
                              </span>
                              <figcaption>
                                <h3>River View</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>2500 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>

                        <div className="col-md-6 hidden-sm hidden-xs">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img src="./yachis/offers5.jpg" alt="" />
                              <span className="kf-thumb-heading">
                                Yoga Hall
                              </span>
                              <figcaption>
                                <h3>Free Breakfast</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>2000 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section>
        <div className="container">
          <div className="row">
            {/* <!--HEADING 1 START--> */}
            <div classN="col-md-12">
              <div className="kf-heading-1">
                <h3>Our Pricing</h3>
                <span>
                  <img
                    src="https://theyachis.com/yachis/underline.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>luxury rooms</h5>
                  <p>Special Services</p>
                </div>
                <figure>
                  <img
                    src="https://theyachis.com/yachis/price-tag.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup>
                        3500
                        <sub>night</sub>
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. Breakfast</li>
                      <li>Private Balcony</li>
                      <li>Mountain View</li>
                      <li>Free Wi-Fi</li>
                      <li>Bathroom</li>
                      <li>Room Service</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="https://theyachis.com/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>comfort Room</h5>
                  <p>Special Services</p>
                </div>
                <figure>
                  <img
                    src="https://theyachis.com/yachis/price-tag2.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup>
                        2500
                        <sub>night</sub>
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. Breakfast</li>
                      <li>River View</li>
                      <li>Free Wi-Fi</li>
                      <li>Bathroom</li>
                      <li>Room Service</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="https://theyachis.com/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Deluxe Room</h5>
                  <p>Special Services</p>
                </div>
                <figure>
                  <img
                    src="https://theyachis.com/yachis/price-tag3.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup>
                        1800
                        <sub>night</sub>
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. Breakfast</li>
                      <li>Hill View</li>
                      <li>Free Wi-Fi</li>
                      <li>Bathroom</li>
                      <li>Room Service</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="https://theyachis.com/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Rooms;
