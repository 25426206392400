import React, { useEffect } from "react";
import Aos from 'aos';
import "aos/dist/aos.css";
import { motion } from "framer-motion";

function Home() {

  useEffect(()=>{
    Aos.init({duration:2000});
  },[]);

  return (
    <>
      <div className="container">
        <div className="kf-home-banner">
          {/* <div id="owl-demo-main" className="owl-carousel owl-theme">
          <div className="item">
            <figure>
              <img
                src="./yachis/images/hotel-in-rishikesh.jpeg"
                alt="Best Hotel in Laxman Jhula"
                title="Best hotel in laxman jhula rishikesh"
              />
              <figcaption>
                <h1>
                  Welcome to The Yachi's: Your Luxurious Hotel in Rishikesh by
                  the Ganga
                </h1>
                <div className="rw-sentence">
                  <div className="rw-words rw-words-1">
                    <span>Book Your Holidays at Dream Location</span>
                    <span>Book Your Dream Hotel Destinations</span>
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="item">
            <figure>
              <img
                src="./yachis/images/best-price-hotel-in-tapovan.jpeg"
                alt="Best Price Hotel in Tapovan"
                title="luxury hotel in Rishikesh"
              />
              <figcaption>
                <p>
                  Best Hotel and Resort in Rishikesh near Ganga - The Yachi's
                </p>
                <h2>
                  Book Your Holidays at<span> Dream </span>Location
                </h2>
              </figcaption>
            </figure>
          </div>
          <div className="item">
            <figure>
              <img
                src="./yachis/images/hotel-in-tapovan-rishikesh.jpeg"
                alt="Hotel in Tapovan"
                title="3 star hotel in Rishikesh"
              />
              <figcaption>
                <p>Best Price Hotel in Rishikesh Uttarakhand</p>
                <h2>
                  Book Your Holidays at<span> Hotel </span>Rishikesh
                </h2>
              </figcaption>
            </figure>
          </div>
        </div> */}

          <div
            id="carouselExampleFade carouselExampleAutoplaying carouselExampleCaptions"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            {/* <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div> */}
            <div className="carousel-inner">
              <div className="carousel-item active test007">
                <img
                  src="./yachis/images/hotel-in-rishikesh.jpg"
                  className="d-block w-100 test007"
                  alt="Best Hotel In Laxman Jhula"
                />
              </div>
              <div className="carousel-item">
                <img
                  src="./yachis/images/best-price-hotel-in-tapovan.jpeg"
                  className="d-block w-100"
                  alt="Best Price Hotel in Tapovan"
                />
              </div>
              <div className="carousel-item">
                <img
                  src="./yachis/images/hotel-in-tapovan-rishikesh.jpeg"
                  className="d-block w-100"
                  alt="Hotel in Tapovan"
                />
              </div>
            </div>
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade #carouselExampleAutoplaying " data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade #carouselExampleAutoplaying " data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button> */}
          </div>

          {/* <span>
          <i className="fa fa-angle-down"></i>
        </span> */}
        </div>
      </div>
      {/* <!--HOME PAGE BANNER END--> */}

      <div className="kf_content_wrap">
        {/* <!--SPECIAL OFFER SECTION START--> */}
        <section className="kf-special-offer-wrap">
          <div className="container">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3>Our Special Offers</h3>
                <span>
                  <img
                    src="https://theyachis.com/yachis/underline.png"
                    alt=""
                  />
                </span>
                <p>
                  Book The Yachi's Hotel in Rishikesh - Checkout hotel rates,
                  images, locations, web exclusive offers & deals, services &
                  amenities and book your stay online in Rishikesh directly from
                  Official Website of The Yachi's Hotel to enjoy complimentary
                  Breakfast, WiFI, Pay@Hotel. Best Deals Guaranteed!
                </p>
              </div>
            </div>
            <div className="bx-wrapper">
              <div className="bx-viewport">
                <ul className="bxslider">
                  <li className="bx-clone">
                    <div className="kf_content-des">
                      {/* <!--HEADING 1 END--> */}
                      <div className="col-md-4 col-sm-6" data-aos="fade-down">
                        {/* <!--SPECIAL OFFER THUMB START--> */}
                        <div className="thumb">
                          <figure>
                            <img
                              src="./yachis/images/offers1.jpg"
                              alt=""
                            />
                            <figcaption>
                              <h3>Free Kids</h3>
                              <span>Special Offer</span>
                              <p>
                                The Yachi's is the best hotel in Budget at
                                Rishikesh with modern Facilities & guest
                                facilities in Rishikesh book your room now!
                              </p>
                              <div className="kf-offer-price">
                                <small>From</small>
                                <strong>1000 ₹</strong>
                                <a
                                  href="https://theyachis.com/booknow/"
                                  className="medium-btn"
                                >
                                  Book Now
                                </a>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                        {/* <!--SPECIAL OFFER THUMB END--> */}
                      </div>
                      <div className="col-md-8 col-sm-6">
                        <div className="col-md-6" data-aos="fade-down-right">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img
                                src="https://theyachis.com/yachis/offers2.jpg"
                                alt=""
                              />
                              <span className="kf-thumb-heading">
                                Balcony Rooms
                              </span>
                              <figcaption>
                                <h3>Free WiFi</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>3500 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>

                        <div className="col-md-6" data-aos="fade-down-left">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img
                                src="https://theyachis.com/yachis/offers3.jpg"
                                alt=""
                              />
                              <span className="kf-thumb-heading">
                                Deluxe Room
                              </span>
                              <figcaption>
                                <h3>Mountain View</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>1800 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>

                        <div className="col-md-6 hidden-sm hidden-xs" data-aos="fade-up-right">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img
                                src="https://theyachis.com/yachis/offers4.jpg"
                                alt=""
                              />
                              <span className="kf-thumb-heading">
                                Super Deluxe Room
                              </span>
                              <figcaption>
                                <h3>River View</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>2500 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>

                        <div className="col-md-6 hidden-sm hidden-xs" data-aos="fade-up-left">
                          {/* <!--SPECIAL OFFER THUMB START--> */}
                          <div className="thumb overlay-thumb">
                            <figure>
                              <img
                                src="https://theyachis.com/yachis/offers5.jpg"
                                alt=""
                              />
                              <span className="kf-thumb-heading">
                                Yoga Hall
                              </span>
                              <figcaption>
                                <h3>Free Breakfast</h3>
                                <span>Special Offer</span>
                                <div className="kf-offer-price">
                                  <small>From</small>
                                  <strong>2000 ₹</strong>
                                  <a
                                    href="https://theyachis.com/booknow/"
                                    className="medium-btn"
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                          {/* <!--SPECIAL OFFER THUMB END--> */}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!--SPECIAL OFFER SECTION END-->
			 <!--OUR HOTEL SECTION START--> */}
        <section className="kf-tab-services-wrap">
          <div className="container">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3>Welcome To Our Hotel</h3>
                <span>
                  <img
                    src="https://theyachis.com/yachis/underline.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="kf-tab-services">
              <div className="tab-content">
                <div>
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-5" data-aos="fade-right">
                      <figure>
                        <img
                          src="https://theyachis.com/yachis/services-tab-1.jpg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>The World Class</span>
                        <h5>Restaurant & Cafe</h5>
                        <p>
                          We have Restaurant in our Hotel. We also provide
                          Vegetarian food and there is Multi Cuisine Restaurant
                          in our Hotel in Tapovan Rishikesh ..
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Best Price Hotel in Tapovan Rishikesh</li>
                          <li>Hotel in Rishikesh</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
                <div>
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>World Capital of Yoga</span>
                        <h5>Yoga</h5>
                        <p>
                          Rishikesh is the world capital of yoga. This is the
                          best place for doing Yoga. There are many yoga teacher
                          training schools in Laxmanjhula Tapovan. many people
                          come here from all over the world to doing Yoga and
                          Meditation Courses.
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Yoga Teacher Training Course in Rishikesh</li>
                          <li>Yoga in Rishikesh</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                    <div className="col-md-5" data-aos="fade-left">
                      <figure>
                        <img
                          src="https://theyachis.com/yachis/services-tab-2.jpg"
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
                <div>
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-5" data-aos="fade-right">
                      <figure>
                        <img
                          src="https://theyachis.com/yachis/services-tab-3.jpg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>Adventure in India</span>
                        <h5>Adventure Activity</h5>
                        <p>
                          We provide several Adventure Activities such as White
                          water Rafting, Camping, Trekking, Jungle Safari,
                          Bungee Jumping and many other activities in Rishikesh
                          Uttarakhand India.
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Rafting, Camping, Trekking </li>
                          <li>Jungle Safari, Bungee Jumping</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
                <div>
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>Rishikesh The Yoga City</span>
                        <h5>Rishikesh</h5>
                        <p>
                          Rishikesh is the best place to visit everyone. There
                          are several places to visit in Rishikesh, such as
                          Triveni Ghat Market, Parmarth Niketan, Ramjhula,
                          Laxmanjhula, Betals Ashram, Vaanprasth Aashram, Ganga
                          River, Marin Drive, Bairaz, Rajaji National Park and
                          other places.{" "}
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Rishikesh</li>
                          <li>Uttarakhand India</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                    <div className="col-md-5" data-aos="fade-left">
                      <figure>
                        <img
                          src="https://theyachis.com/yachis/services-tab-4.jpg"
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
              </div>
              {/* <!-- Tab panes end--> */}
            </div>
          </div>
        </section>
        {/* <!--OUR HOTEL SECTION END--> */}

        {/* <!--ACCOMODATION  SECTION START--> */}
        <section>
          <div className="container">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3>Accommodations</h3>
                <span>
                  <img
                    src="https://theyachis.com/yachis/underline.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="row">
              <div className="col-md-4 col-sm-6" data-aos="fade-right">
                {/* <!--ACCOMODATION START--> */}
                <motion.div className="kf-accomodation-dec accom1" whileHover={{scale:1,boxShadow:"5px 10px 8px  rgb(63 122 126)"}}>
                  <a href="https://theyachis.com/rooms/">
                    <figure>
                      <img
                        src="https://theyachis.com/yachis/acconodation-1.jpg"
                        alt=""
                      />
                      <figcaption>
                        <i className="fa fa-link"></i>
                      </figcaption>
                    </figure>
                  </a>
                  <div className="text ">
                    <h5>
                      <a href="https://theyachis.com/rooms/">
                        Room with Balcony
                      </a>
                    </h5>
                    <p>
                      Hotel The Yachi's providing Luxury rooms with Balcony and
                      private bathroom. Those are the best rooms of the Hotel.
                      You must check in!
                    </p>
                    <ul className="servises-meta">
                      <li>
                        Price: 3500 ₹ &nbsp;&nbsp;{" "}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </motion.div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6" data-aos="fade-down">
                {/* <!--ACCOMODATION START--> */}
                <motion.div className="kf-accomodation-dec accom2" whileHover={{scale:1,boxShadow:"5px 10px 8px  rgb(63 122 126)"}}>
                  <a href="https://theyachis.com/rooms/">
                    <figure>
                      <img
                        src="https://theyachis.com/yachis/acconodation-2.jpg"
                        alt=""
                      />
                      <figcaption>
                        <i className="fa fa-link"></i>
                      </figcaption>
                    </figure>
                  </a>
                  <div className="text">
                    <h5>
                      <a href="https://theyachis.com/rooms/">Deluxe Room</a>
                    </h5>
                    <p>
                      Hotel The Yachi's providing Deluxe rooms with private
                      bathroom. Those are the best rooms of the Hotel for family
                      purpose. You must check in Hotel!
                    </p>
                    <ul className="servises-meta">
                      <li>
                        Price: 1800 ₹ &nbsp;&nbsp;{" "}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </motion.div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  hidden-sm" data-aos="fade-left">
                {/* <!--ACCOMODATION START--> */}
                <motion.div className="kf-accomodation-dec accom3" whileHover={{scale:1,boxShadow:"5px 10px 8px  rgb(63 122 126)"}} >
                  <a href="https://theyachis.com/rooms/">
                    <figure>
                      <img
                        src="https://theyachis.com/yachis/acconodation-3.jpg"
                        alt=""
                      />
                      <figcaption>
                        <i className="fa fa-link"></i>
                      </figcaption>
                    </figure>
                  </a>
                  <div className="text">
                    <h5>
                      <a href="https://theyachis.com/rooms/">
                        Super Deluxe Room
                      </a>
                    </h5>
                    <p>
                      Hotel The Yachi's providing Super Deluxe rooms with
                      private bathroom. Those are the best rooms of the Hotel
                      for the newly married couples. Must visit once!
                    </p>
                    <ul className="servises-meta">
                      <li>
                        Price: 2500 ₹ &nbsp;&nbsp;{" "}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </motion.div>
                {/* <!--ACCOMODATION END--> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!--ACCOMODATION  SECTION END--> */}
      </div>

      {/* <!--EDU2 FOOTER WRAP START-->
		<!--NEWS LETTERS START-->

		<!--NEWS LETTERS END-->
		<!--FOOTER START--> */}
    </>
  );
}

export default Home;
