import React from "react";
import { Link } from "react-router-dom";

function Trekking() {
  return (
    <>
      <div className="kf-home-banner inner-banner">
        <div className="container">
          <h3>Trekking in Tapovan Laxmanjhula Rishikesh</h3>
          <span>
            <img src="./yachis/underline.png" alt="" />
          </span>
          <ul className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="#">Activity</Link>
            </li>
            <li>
              <Link to="/trekking/">Trekking</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <!--HOME PAGE BANNER END--> */}
      <div className="kf_content_wrap">
        {/* <!--SPECIAL OFFER SECTION START--> */}
        <section className="kf-aboutus-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="video-thumb">
                  <img src="./yachis/trekking.jpg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text">
                  <h4>Trekking in Rishikesh</h4>
                  <h5>Trekking in Mountains at Best Price </h5>
                  <p>
                    {" "}
                    We welcome our guests to our Hotel, in which we are
                    providing the Trekking in Tapovan, Trekking in Shivpuri,
                    Trekking in Chopta, Trekking in Mountains, Trekking in Hills
                    and so many places where everyone can enjoy the view and the
                    Nature.
                  </p>
                  <p>
                    {" "}
                    We welcome our guests to our Hotel, in which we are
                    providing the Trekking in Tapovan, Trekking in Shivpuri,
                    Trekking in Chopta, Trekking in Mountains, Trekking in Hills
                    and so many places where everyone can enjoy the view and the
                    Nature.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--LATEST ARTICAL  SECTION START--> */}
        <section className="kf-latest-artical-bg">
          <div className="container">
            <div className="row">
              {/* <!--HEADING 1 START--> */}
              <div className="col-md-12">
                <div className="kf-heading-1">
                  <h3>Trekking in Rishikesh</h3>
                  <span>
                    <img src="./yachis/underline.png" alt="" />
                  </span>
                </div>
              </div>
              {/* <!--HEADING 1 END--> */}

              <div className="kf-latest-artical right-side">
                <figure className="col-md-7 col-sm-12">
                  <img
                    src="./yachis/trekking-in-uttarakhand.jpg"
                    alt=""
                  />
                </figure>
                <div className="text col-md-6 col-sm-12">
                  <h6>Top Trekking in Rishikesh</h6>
                  <p>
                    Hotel The Yachi's providing Tent Trekking in Rishikesh,
                    Shivpuri, Neelkanth Road, Neergarh, Chopta and many places.
                  </p>
                  <Link className="small-btn" to="/booknow/">
                    Book your Hotel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--LATEST ARTICAL  SECTION END--> */}
      </div>

      {/* <!--PRICEING  SECTION START--> */}
      <section>
        <div className="container">
          <div className="row">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3> Camp Pricing</h3>
                <span>
                  <img src="./yachis/underline.png" alt="" />
                </span>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>luxury Camps</h5>
                  <p>Special Services</p>
                </div>
                <figure>
                  <img
                    src="./yachis/camping-price-high.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup>
                        2500
                        <sub>night</sub>
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. 3 Meals</li>
                      <li>Mountain View</li>
                      <li>Bathroom</li>
                      <li>White Water Rafting</li>
                      <li>Sun Rise Trekking</li>
                    </ul>
                  </figcaption>
                </figure>
                <Link to="/booknow/">
                  <button>Book Now!</button>
                </Link>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>comfort Camps</h5>
                  <p>Special Services</p>
                </div>
                <figure>
                  <img
                    src="./yachis/camping-price.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup>
                        1500
                        <sub>night</sub>
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. 3 Meals</li>
                      <li>Mountain View</li>
                      <li>Bathroom</li>
                      <li>White Water Rafting</li>
                      <li>Sun Rise Trekking</li>
                    </ul>
                  </figcaption>
                </figure>
                <Link to="/booknow/">
                  <button>Book Now!</button>
                </Link>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Deluxe Camps</h5>
                  <p>Special Services</p>
                </div>
                <figure>
                  <img
                    src="./yachis/camping-price-low.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup>
                        1000
                        <sub>night</sub>
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. 3 Meals</li>
                      <li>Mountain View</li>
                      <li>Bathroom</li>
                      <li>White Water Rafting</li>
                      <li>Sun Rise Trekking</li>
                    </ul>
                  </figcaption>
                </figure>
                <Link to="/booknow/">
                  <button>Book Now!</button>
                </Link>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Trekking;
