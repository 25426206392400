import React from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./components/home";
import Bunjee_Jump from "./components/bunjee_jump";
import Aboutus from "./components/aboutus";
import Rooms from "./components/rooms";
import Rishikesh from "./components/rishikesh";
import Trekking from "./components/trekking";
import Jungle_Safari from "./components/juglesafari";
import Camping from "./components/camping";

function App() {

  return (
    <BrowserRouter>
      <div className="kode_wrapper">
        {/* <!--HEADER START--> */}
        <header>
          
			{/*<!--KF HOTEL NAVIGATION BAR START--> */}
          <div className="kf-navigation-wrap bg-body-tertiary fixed-top">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-4">
                  {/* <!--KF HOTEL LOGO START--> */}
                  <div className="kf-logo">
                    <Link to="/">
                      <img src="./yachis/logo.png" alt="" />
                    </Link>
                  </div>
                  {/* <!--KF HOTEL LOGO END--> */}
                </div>
                <div className="col-md-9 col-sm-8">
                  {/* <!--KF HOTEL NAV START--> */}
                  <div className="kf-nav">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/aboutus">About</Link>
                      </li>
                      <li>
                        <Link to="/rooms">Rooms</Link>
                      </li>
                      <li>
                        <a href="#">Activity</a>
                        <ul>
                          <li>
                            <Link to="/yoga">Yoga</Link>
                          </li>
                          <li>
                            <Link to="/camping">Camping</Link>
                          </li>
                          <li>
                            <Link to="/trekking">
                              Trekking
                            </Link>
                          </li>
                          <li>
                            <Link to="/jungle-safari">
                              Jungle Safari
                            </Link>
                          </li>
                          <li>
                            <Link to="/bunjee">
                              Bungee Jumping
                            </Link>
                          </li>
                          <li>
                            <Link to="/packages">
                              Packages
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/rishikesh">Rishikesh</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                    {/* <!--Responsive Menu END-->
								<!--DL Menu Start--> */}
                    <div id="kode-responsive-navigation" className="dl-menuwrapper">
                      <button className="dl-trigger">Open Menu</button>
                      <ul className="dl-menu">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item kode-parent-menu">
                          <Link to="/aboutus">About</Link>
                        </li>
                        <li>
                          <Link to="/rooms">Rooms</Link>
                        </li>
                        <li className="menu-item kode-parent-menu">
                          <a href="#">Activity</a>
                          <ul className="dl-submenu">
                            <li>
                              <Link to="/yoga">Yoga</Link>
                            </li>
                            <li>
                              <Link to="/camping">Camping</Link>
                            </li>
                            <li>
                              <Link to="/trekking">
                                Trekking
                              </Link>
                            </li>
                            <li>
                              <Link to="/jungle-safari">
                                Jungle Safari
                              </Link>
                            </li>
                            <li>
                              <Link to="/bunjee">
                                Bungee Jumping
                              </Link>
                            </li>
                            <li>
                              <Link to="/packages">
                                Packages
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/rishikesh">Rishikesh</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </div>
                    {/* <!--DL Menu END-->
			                    
								
								<!--KF HOTEL SEARCH WRAP START--> */}
                    <div className="kf-nav-search">
                      <Link
                        to="/booknow/"
                        className="small-btn shadow-shadow"
                      >
                        Book Now
                      </Link>
                    </div>
                    {/* <!--KF HOTEL SEARCH WRAP END--> */}
                  </div>
                  {/* <!--KF HOTEL NAV END--> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!--KF HOTEL NAVIGATION BAR END--> */}
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home></Home>} />
            <Route path="/bunjee" element={<Bunjee_Jump></Bunjee_Jump>} />
            <Route path="/aboutus" element={<Aboutus></Aboutus>} />
            <Route path="/rooms" element={<Rooms></Rooms>} />
            <Route path="/rishikesh" element={<Rishikesh></Rishikesh>} />
            <Route path="/trekking" element={<Trekking></Trekking>} />
            <Route path="/jungle-safari" element={<Jungle_Safari></Jungle_Safari>} />
            <Route path="/camping" element={<Camping></Camping>} />
          </Routes>
        </main>
        <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="widget">
                <h5>Hotel The Yachi's</h5>
                <div className="text">
                  <p>
                    The Yachi's is the one of the Best price Hotel in Tapovan
                    Rishikesh. The Yachi's is the Best Hotel & Accommodation in
                    Laxmanjhula Rishikesh. This is the most booked hotel in
                    Laxmanjhula in the past month.
                  </p>
                  <br />
                  <Link to="https://g.page/r/CTs5iX5JfTOvEAI/review">
                    <img src="./yachis/google-review.png" />{" "}
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="widget widget-twitter">
                <h5>Quick Links</h5>
                {/* <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftheyachis%2F&tabs=timeline&width=300&height=310&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                  width="300"
                  height="310"
                ></iframe> */}
                <ul>
                  <li>
                    <p>Home</p>
                  </li>
                  <li>
                    <p>About us</p>
                  </li>
                  <li>
                    <p>Rooms</p>
                  </li>
                  <li>
                    <p>Activity</p>
                  </li>
                  <li>
                    <p>Contacts</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="widget widget-twitter">
                <h5>Get in Touch</h5>
                <ul>
                  <li>
                    <i className="fa fa-map-marker"></i>
                    <Link to="https://bit.ly/3wIol3h">
                      The Yachi's, Tapovan Hills, Upper Tapovan, Rishikesh,
                      Uttarakhand, 249137
                    </Link>
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i> <a href="#">theyachis@gmail.com</a>
                  </li>
                  <li>
                    <i className="fa fa-phone"></i> 
                    <a href="#">+91-9755111748</a>
                  </li>
                </ul>
                <Link to="/booknow/" className="small-btn">
                  Book Now!
                </Link>
              </div>
            </div>
          </div>
          <div className="kf_copyright_wrap">
            <ul className="kf-footer-nav">
              <p>
                Copyright <i className="bx bx-copyright"></i>2022 - Designed By
                <Link to="/" target="_blank">
                  The Yachi's
                </Link>
              </p>
            </ul>

            <ul className="kf-footer-social">
              <li>
                <Link href="https://www.facebook.com/theyachis/">
                  <i className="fa fa-facebook"></i>
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/theyachis">
                  <i className="fa fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/theyachis/">
                  <i className="fa fa-instagram"></i>
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UCVsQouzZaS3u9ZWtZLg5VDg">
                  <i className="fa fa-youtube"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
