import React from "react";

function Rishikesh() {
  return (
    <>
      <div className="kf-home-banner inner-banner">
        <div className="container">
          <h3>Rishikesh</h3>
          <span>
            <img src="./yachis/underline.png" alt="" />
          </span>
          <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/rishikesh/">Rishikesh</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="kf_content_wrap">
        {/* <!--SPECIAL OFFER SECTION START--> */}
        <section className="kf-aboutus-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text">
                  <h4>Welcome to Rishikesh!</h4>
                  <h5>Explore Rishikesh : Best of Rishikesh, India</h5>
                  <p>
                    {" "}
                    We welcome our guests to our Hotel, which is situated in
                    Laxmanjhula, Rishikesh Uttarakhand.
                  </p>
                  <p>
                    Rishikesh is located in the foothills of the Uttarakhand
                    Himalayas. Rishikesh is a destination for all of the who are
                    nature lovers, mountain lovers and who loves travelling in
                    mountains.
                  </p>
                  <p>
                    {" "}
                    The noble magnet of Rishikesh is none other than the great
                    River the Ganges herself, running fleetly throughout the
                    megacity. In the ancient time and still so numerous yogis,
                    rishis, pundits, and sannyasis attracted to Rishikesh to
                    learn yoga in this peaceful position.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="video-thumb">
                  <img
                    src="./yachis/rishikesh.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--ACCOMODATION  SECTION START--> */}
        <section>
          <div className="container">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3>Rishikesh - the world capital of Yoga</h3>
                <span>
                  <img
                    src="./yachis/underline.png"
                    alt=""
                  />
                </span>
                <p>
                  There are several places to visit in Rishikesh, such as
                  Triveni Ghat Market, Parmarth Niketan, Ramjhula, Laxmanjhula,
                  Betals Ashram, Vaanprasth Aashram, Ganga River, Marin Drive,
                  Bairaz, Rajaji National Park and other places.
                </p>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="row">
              <div className="col-md-4 col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/triveni-ghat-rishikesh.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Triveni Ghat Rishikesh</h5>
                    <p>
                      Triveni Ghat is in Rishikesh. Many people come here for
                      Ganga Aarti in the evening. Triveni Ghat market is one of
                      the best attaraction of the Rishikesh.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/parmarth-niketan.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Parmarth Niketan</h5>
                    <p>
                      Parmarth Niketan is very attrative place for tourists.
                      several people come here to visit Parmarth Niketan
                      Rishikesh from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  hidden-sm">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/ramjhula.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Ramjhula, Rishikesh</h5>
                    <p>
                      Ramjhula is very attrative place for tourists. several
                      people come here to visit Ramjhula Rishikesh from all over
                      the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/laxmanjhula.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Laxmanjhula</h5>
                    <p>
                      Laxmanjhula is very attrative place for tourists. several
                      people come here to visit Laxmanjhula Rishikesh from all
                      over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/betals-ashram.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Betals Ashram</h5>
                    <p>
                      Parmarth Niketan is very attrative place for tourists.
                      several people come here to visit Parmarth Niketan
                      Rishikesh from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/vaanprasth-aashram.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Vaanprasth Aashram</h5>
                    <p>
                      Vaanprasth Aashram is very attrative place for tourists.
                      several people come here to visit Vaanprasth Aashram
                      Rishikesh from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/ganga-river.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Ganga River Rishikesh</h5>
                    <p>
                      Ganga River Rishikesh is very attrative place for
                      tourists. several people come here to visit Ganga River
                      from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/marin-drive.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Marin Drive Rishikesh</h5>
                    <p>
                      Marin Drive is very attrative place for tourists. several
                      people come here to visit Marin Drive Rishikesh from all
                      over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/bairaz-rishikesh.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Bairaz Rishikesh</h5>
                    <p>
                      Parmarth Niketan is very attrative place for tourists.
                      several people come here to visit Parmarth Niketan
                      Rishikesh from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/rajaji-national-park.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Rajaji National Park</h5>
                    <p>
                      Rajaji National Park is very attrative place for tourists.
                      several people come here to visit Rajaji National Park
                      Rishikesh from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/neelkanth-mahadev-mandir.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Neelkanth Temple</h5>
                    <p>
                      Neelkanth Mahadev Mandir is situated in Pauri Garhwal.
                      several people come here to visit Neelkanth Temple
                      Rishikesh from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
              <div className="col-md-4  col-sm-6">
                {/* <!--ACCOMODATION START--> */}
                <div className="kf-accomodation-dec">
                  <figure>
                    <img
                      src="./yachis/neergarh-waterfall.jpg"
                      alt=""
                    />
                    <figcaption>
                      <i className="fa fa-link"></i>
                    </figcaption>
                  </figure>
                  <div className="text">
                    <h5>Neergarh Waterfall</h5>
                    <p>
                      Neergarh Waterfall is very attrative place for tourists.
                      several people come here to visit Neergarh Waterfall
                      Rishikesh from all over the world.
                    </p>
                  </div>
                </div>
                {/* <!--ACCOMODATION END--> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!--ACCOMODATION  SECTION END--> */}
      </div>
    </>
  );
}

export default Rishikesh;
