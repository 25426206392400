import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './bootstrap.min.css';
import './color.css';
import './component.css';
import './effects.css';
import './font-awesome.min.css';
import './jquery.bxslider.css';
// import './prettyPhoto.css';
import './responsive.css';
import './selectric.css';
import './shortcodes.css';
import './style.css';
import './style(1).css';
import './typography.css';
import './widget.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
