import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";

function Aboutus() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <!--HOME PAGE BANNER START--> */}
      {/* <div className="kf-home-banner inner-banner">
        <div className="container">
          <h3>About Us</h3>
          <span>
            <img src="./yachis/underline.png" alt="" />
          </span>
          <ul class="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/aboutus/">About Us</a>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-9 inner-banner" data-aos="fade-right">
            <img src="./yachis/hotels-in-tapovan.jpg" alt="" />
          </div>
          <div className="col-3 innerli" data-aos="fade-left">
            <ul>
              <li>
                <img src="./yachis/hotels-in-tapovan.jpg" alt="" />
              </li>
              <li>
                <img src="./yachis/hotels-in-tapovan.jpg" alt="" />
              </li>
              <li>
                <img src="./yachis/hotels-in-tapovan.jpg" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--HOME PAGE BANNER END--> */}
      <div className="kf_content_wrap">
        {/* <!--SPECIAL OFFER SECTION START--> */}
        <section className="kf-aboutus-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text">
                  <h4>Welcome to The Yachi's Hotel</h4>
                  <h5>Best Price Hotel in Laxmanjhula Rishikesh</h5>
                  <p>
                    {" "}
                    We welcome our guests to our Hotel, which is situated in the
                    foothills of Uttarakhand Himalayas.
                  </p>
                  <p>
                    The Yachi's Hotel is one of the Best price Hotel in
                    Rishikesh. Our Hotel is best for family, couple, newly
                    married couple, friends ans alsole for all purpose.
                  </p>
                  <p>
                    Book The Yachi's Hotel in Rishikesh - Checkout hotel rates,
                    images, locations, web exclusive offers & deals, services &
                    amenities and book your stay online in Rishikesh directly
                    from Official Website of The Yachi's Hotel to enjoy
                    complimentary Breakfast, WiFI, Pay@Hotel. Best Deals
                    Guaranteed!
                  </p>
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-left">
                <div className="video-thumb">
                  <img src="./yachis/best-price-hotel-rishikesh.jpeg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="kf-tab-services-wrap">
          <div className="container">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3>Welcome To Our Hotel</h3>
                <span>
                  <img src="./yachis/underline.png" alt="" />
                </span>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="kf-tab-services">
              <div>
                <div id="restaurant">
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-5" data-aos="fade-right">
                      <figure>
                        <img src="./yachis/services-tab-1.jpg" alt="" />
                      </figure>
                    </div>
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>The World Class</span>
                        <h5>Restaurant & Cafe</h5>
                        <p>
                          We have Restaurant in our Hotel. We also provide
                          Vegetarian food and there is Multi Cuisine Restaurant
                          in our Hotel in Tapovan Rishikesh ..
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Best Price Hotel in Tapovan Rishikesh</li>
                          <li>Hotel in Rishikesh</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
                <div id="gym">
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>World Capital of Yoga</span>
                        <h5>Yoga</h5>
                        <p>
                          Rishikesh is the world capital of yoga. This is the
                          best place for doing Yoga. There are many yoga teacher
                          training schools in Laxmanjhula Tapovan. many people
                          come here from all over the world to doing Yoga and
                          Meditation Courses.
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Yoga Teacher Training Course in Rishikesh</li>
                          <li>Yoga in Rishikesh</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                    <div className="col-md-5" data-aos="fade-left">
                      <figure>
                        <img src="./yachis/services-tab-2.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
                <div id="pickUp">
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-5" data-aos="fade-right">
                      <figure>
                        <img src="./yachis/services-tab-3.jpg" alt="" />
                      </figure>
                    </div>
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>Adventure in India</span>
                        <h5>Adventure Activity</h5>
                        <p>
                          We provide several Adventure Activities such as White
                          water Rafting, Camping, Trekking, Jungle Safari,
                          Bungee Jumping and many other activities in Rishikesh
                          Uttarakhand India.
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Rafting, Camping, Trekking </li>
                          <li>Jungle Safari, Bungee Jumping</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
                <div id="bar">
                  {/* <!-- TAB SERVICES CONTANT START--> */}
                  <div className="tab-services-contant">
                    <div className="col-md-7">
                      {/* <!-- TAB TEXT START--> */}
                      <div className="text">
                        <span>Rishikesh The Yoga City</span>
                        <h5>Rishikesh</h5>
                        <p>
                          Rishikesh is the best place to visit everyone. There
                          are several places to visit in Rishikesh, such as
                          Triveni Ghat Market, Parmarth Niketan, Ramjhula,
                          Laxmanjhula, Betals Ashram, Vaanprasth Aashram, Ganga
                          River, Marin Drive, Bairaz, Rajaji National Park and
                          other places.{" "}
                        </p>
                        {/* <!-- SERVICESS META START--> */}
                        <ul className="servises-meta">
                          <li>Rishikesh</li>
                          <li>Uttarakhand India</li>
                        </ul>
                        {/* <!-- SERVICESS META END--> */}
                        <a
                          href="https://theyachis.com/booknow/"
                          className="small-btn shadow-shadow"
                        >
                          Book Now
                        </a>
                      </div>
                      {/* <!-- TAB TEXT END--> */}
                    </div>
                    <div className="col-md-5" data-aos="fade-left">
                      <figure>
                        <img src="./yachis/services-tab-4.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                  {/* <!-- TAB SERVICES CONTANT END--> */}
                </div>
              </div>
              {/* <!-- Tab panes end--> */}
            </div>
          </div>
        </section>
        {/* <!--OUR HOTEL SECTION END-->
		 
			 
				<!--LATEST ARTICAL  SECTION START--> */}
        <section className="kf-latest-artical-bg">
          <div className="container">
            <div className="row">
              {/* <!--HEADING 1 START--> */}
              <div className="col-md-12">
                <div className="kf-heading-1">
                  <h3>Best Hotel in Laxmanjhula</h3>
                  <span>
                    <img src="./yachis/underline.png" alt="" />
                  </span>
                </div>
              </div>
              {/* <!--HEADING 1 END--> */}
              <div>
                <div className="row">
                  <div className="col-md-6 col-sm-12" >
                    <motion.img src="./yachis/hotel-in-tapovan.jpeg" alt="" className="lakshmanjhulaimg" whileHover={{scale:1.1,boxShadow:"5px 10px 8px  rgb(63 122 126)"}}/>
                  </div>
                  <div className="col-md-6 col-sm-12" >
                    <motion.img src="./yachis/guest-house-in-tapovan.jpeg" alt="" className="lakshmanjhulaimg" whileHover={{scale:1.1,boxShadow:"5px 10px 8px  rgb(63 122 126)"}}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <br/>
                    <h6>Best Price Hotel in Laxmanjhula Rishikesh </h6>
                    <br/>
                    <p>
                      The Yachi's is the best hotel in Budget at Rishikesh with
                      modern & guest facilities in Rishikesh book
                      your room now! We provide several Adventure Activities
                      such as White water Rafting, Camping, Trekking, Jungle
                      Safari, Bungee Jumping and many other activities in
                      Rishikesh Uttarakhand India.
                    </p>
                    <br/>
                    <a className="small-btn shadow-shadow" href="/booknow/">
                      Book your Hotel
                    </a>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <br/>
                    <h6>Book The Yachi's Hotel in Rishikesh</h6>
                    <br/>
                    <p>
                      Book The Yachi's Hotel in Rishikesh - Checkout hotel
                      rates, images, locations, web exclusive offers & deals,
                      services & amenities and book your stay online in
                      Rishikesh directly from Official Website of The Yachi's
                      Hotel to enjoy complimentary Breakfast, WiFI, Pay@Hotel.
                      Best Deals Guaranteed!
                    </p>
                    <br/>
                    <a className="small-btn shadow-shadow" href="/booknow/">
                      Book your Hotel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--LATEST ARTICAL  SECTION END--> */}
      </div>
    </>
  );
}

export default Aboutus;
