import React from "react";

function Jungle_Safari() {
  return (
    <>
      <div className="kf-home-banner inner-banner">
        <div className="container">
          <h3>Jungle Safari in Rishikesh</h3>
          <span>
            <img src="./yachis/underline.png" alt="" />
          </span>
          <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#">Activity</a>
            </li>
            <li>
              <a href="/jungle-safari/">Jungle Safari</a>
            </li>
          </ul>
        </div>
      </div>
      {/* <!--HOME PAGE BANNER END--> */}
      <div className="kf_content_wrap">
        {/* <!--SPECIAL OFFER SECTION START--> */}
        <section className="kf-aboutus-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="video-thumb">
                  <img
                    src="./yachis/jungle-safari.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text">
                  <h4>Jungle Safari in Rishikesh</h4>
                  <p> Jungle Safari in Rajaji National Park Rishikesh </p>
                  <p>Gypsi Safari nearby Rishikesh Uttarakhand </p>
                  <p>
                    {" "}
                    Sitting in a jeep, completely secure, and looking at awful
                    creations of nature roving around you causing no damage,
                    would be such a awful lift.{" "}
                  </p>
                  <p>
                    {" "}
                    Apart from Jungle Safari, There are various adventure sports
                    that Rajaji National Park offers within itself. Activities
                    such as river rafting, cliff jumping, body surfing, and many
                    more. There is a golf course too which can be rented by a
                    group of tourists for a specific duration.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--LATEST ARTICAL  SECTION START--> */}
        <section className="kf-latest-artical-bg">
          <div className="container">
            <div className="row">
              {/* <!--HEADING 1 START--> */}
              <div className="col-md-12">
                <div className="kf-heading-1">
                  <h3>Jungle Safari in Rajaji National Park Rishikesh </h3>
                  <span>
                    <img
                      src="./yachis/underline.png"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              {/* <!--HEADING 1 END--> */}

              <div className="kf-latest-artical right-side">
                <figure className="col-md-7 col-sm-12">
                  <img
                    src="./yachis/jungle-safari-in-uttarakhand.jpg"
                    alt=""
                  />
                </figure>
                <div className="text col-md-6 col-sm-12">
                  <h6>Jungle Safari with Hotel The Yachi's </h6>
                  <p>
                    To Book your Jungle Safari with Hotel The Yachi's Rishikesh{" "}
                    <br />
                    Please call us on: +91-9755111748 <br />
                    Mail us on theyachis@gmail.com <br />
                    Book your spot for Jungle Safari
                  </p>
                  <a className="small-btn" href="/booknow/">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--LATEST ARTICAL  SECTION END--> */}
      </div>

      {/* <!--PRICEING  SECTION START--> */}
      <section>
        <div className="container">
          <div className="row">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div className="kf-heading-1">
                <h3> Jungle Safari-price Pricing</h3>
                <span>
                  <img
                    src="./yachis/underline.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Safari Offer</h5>
                  <p>Safari Offer with Rooms</p>
                </div>
                <figure>
                  <img src="./yachis/safari-price-high.jpg" alt="" />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup> 5000
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. 3 Meals</li>
                      <li>Gypsi Charges: 2700</li>
                      <li>Vehicle Entry Charges: 300</li>
                      <li>Per person charges : 150</li>
                      <li>White Water Rafting</li>
                      <li>Sun Rise Trekking</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Safari Offer</h5>
                  <p>Safari Offer for Indians</p>
                </div>
                <figure>
                  <img src="./yachis/safari-price.jpg" alt="" />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup> 4000
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. 3 Meals</li>
                      <li>Gypsi Charges: 2700</li>
                      <li>Vehicle Entry Charges: 300</li>
                      <li>Per person charges : 150</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Safari Offer</h5>
                  <p>Safari Offer for Forigners</p>
                </div>
                <figure>
                  <img src="./yachis/safari-price-low.jpeg" alt="" />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup> 6000
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>Inc. 3 Meals</li>
                      <li>Gypsi Charges: 2700</li>
                      <li>Vehicle Entry Charges: 300</li>
                      <li>Per person charges : 600</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Jungle_Safari;
