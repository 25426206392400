import React from "react";

function Bunjee_Jump() {
  return (
    <>
      <div className="kf_content_wrap">
        {/* <!--SPECIAL OFFER SECTION START--> */}
        <section className="kf-aboutus-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="video-thumb">
                  <img
                    src="https://theyachis.com/yachis/bungee-jumping.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text">
                  <h4>Highest Bungee Jump in India</h4>
                  <p>Bungee Jumping in Rishikesh Uttarakhand</p>
                  <p>
                    India's Highest Bungee Jump which is nearby Rishikesh
                    Uttarakhand{" "}
                  </p>
                  <p>
                    {" "}
                    Bungy Jumping in Rishikesh is highest bungy Jump in India.
                    Total height is 83 meters. In 2006 it was started by Cap
                    Rahul Nigam. Within a few years it became one of important
                    adventure in Rishikesh. It is one of the best natural place
                    for bungy jumping in India. Apart from Bungy Jumping you can
                    also enjoy Giant Swing and Flying Fox.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--LATEST ARTICAL  SECTION START--> */}
        <section className="kf-latest-artical-bg">
          <div className="container">
            <div className="row">
              {/* <!--HEADING 1 START--> */}
              <div className="col-md-12">
                <div className="kf-heading-1">
                  <h3>India's Highest Bungee Jump in Rishikesh </h3>
                  <span>
                    <img
                      src="https://theyachis.com/yachis/underline.png"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              {/* <!--HEADING 1 END--> */}

              <div class="kf-latest-artical right-side">
                <figure className="col-md-7 col-sm-12">
                  <img
                    src="https://theyachis.com/yachis/bungee-jumping-in-rishikesh.jpg"
                    alt=""
                  />
                </figure>
                <div className="text col-md-6 col-sm-12">
                  <h6>India's Highest Bungee Jump with Hotel The Yachi's </h6>
                  <p>
                    {" "}
                    Bungy Jump 83 measures Height, Giant Swing 83 measures
                    Height, Flying Fox 1 kms long at approx 140 kms per hour
                    speed.
                  </p>
                  <p> Age : min 12 years old</p>
                  <p>
                    {" "}
                    Weight : Limit Minimum 20 kg for flying fox, 30 kg for giant
                    swing and 40 kg for Bungy Jump. Maximum 130 kg weight for
                    flying fox, 120 for giant swing and 110 kg for Bungy Jump.{" "}
                  </p>
                  <a className="small-btn" href="https://theyachis.com/booknow/">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--LATEST ARTICAL  SECTION END--> */}
      </div>

      {/* <!--PRICEING  SECTION START--> */}
      <section>
        <div className="container">
          <div className="row">
            {/* <!--HEADING 1 START--> */}
            <div className="col-md-12">
              <div class="kf-heading-1">
                <h3> Bungee Jump -price Pricing</h3>
                <span>
                  <img
                    src="https://theyachis.com/yachis/underline.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
            {/* <!--HEADING 1 END--> */}
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Bungee Jump Offer</h5>
                  <p>Special Offer</p>
                </div>
                <figure>
                  <img
                    src="https://theyachis.com/yachis/bungee-jump-price-high.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup> 5000{" "}
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>1 Night Stay</li>
                      <li>Inc. 3 Meals</li>
                      <li>Bungee Jump</li>
                      <li>Ramjhula Sightseeing</li>
                      <li>Laxmanjhula Sightseeing</li>
                      <li>Rishikesh Sightseeing</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="https://theyachis.com/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Flying Fox Offer</h5>
                  <p>Special Offer</p>
                </div>
                <figure>
                  <img
                    src="https://theyachis.com/yachis/bungee-jump-price.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup> 3500{" "}
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>1 Night Stay</li>
                      <li>Inc. 3 Meals</li>
                      <li>Flying Fox</li>
                      <li>Ramjhula Sightseeing</li>
                      <li>Laxmanjhula Sightseeing</li>
                      <li>Rishikesh Sightseeing</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="https://theyachis.com/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
            <div className="col-md-4 col-sm-4">
              {/* <!--PRICE TAG START--> */}
              <div className="kf-price-tag">
                <div className="price-tag-heading">
                  <h5>Giant Swing Offer</h5>
                  <p>Special Offer</p>
                </div>
                <figure>
                  <img
                    src="https://theyachis.com/yachis/bungee-jump-price-low.jpg"
                    alt=""
                  />
                  <figcaption>
                    <div className="retail">
                      <span>
                        <sup>₹</sup> 5000{" "}
                      </span>
                    </div>
                    <ul className="retail-meta">
                      <li>1 Night Stay</li>
                      <li>Inc. 3 Meals</li>
                      <li>Giant Swing</li>
                      <li>Ramjhula Sightseeing</li>
                      <li>Laxmanjhula Sightseeing</li>
                      <li>Rishikesh Sightseeing</li>
                    </ul>
                  </figcaption>
                </figure>
                <a href="https://theyachis.com/booknow/">
                  <button>Book Now!</button>
                </a>
              </div>
              {/* <!--PRICE TAG EN--> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bunjee_Jump;
